<script setup lang="ts">
import Layout from "@/Layouts/Layout.vue"
import Checkbox from "primevue/checkbox"
import { Head, Link, useForm } from "@inertiajs/vue3"

defineProps<{
	canResetPassword?: boolean
	status?: string
}>()

const form = useForm({
	email: "",
	password: "",
	remember: true,
})

const submit = () => {
	form.post(route("login"), {
		onFinish: () => {
			form.reset("password")
		},
	})
}
</script>

<template>
	<Layout>
		<Head title="Log in" />

		<section class="mx-auto mt-12 flex flex-col items-center py-6">
			<div class="w-[340px] rounded-2xl bg-gray-50 p-8 shadow-xl">
				<form class="flex flex-col gap-y-4" @submit.prevent="submit">
					<div class="flex flex-col gap-y-1">
						<label for="email">Email Address</label>
						<input
							v-model="form.email"
							autofocus
							class="rounded-md border border-gray-300 p-2 text-gray-900"
							name="email"
							type="email"
						/>
						<strong v-if="form.errors.email" class="text-xs italic text-red-500">{{
							form.errors.email
						}}</strong>
					</div>

					<div class="flex flex-col gap-y-1">
						<label for="password">Password</label>
						<input
							v-model="form.password"
							class="rounded-md border border-gray-300 p-2 text-gray-900"
							name="password"
							type="password"
						/>
						<strong v-if="form.errors.password" class="text-xs italic text-red-500">{{
							form.errors.password
						}}</strong>
					</div>

					<div class="flex items-center gap-x-2">
						<Checkbox
							id="remember"
							v-model="form.remember"
							name="remember"
							:binary="true"
							class="p-checkbox-rounded"
						/>
						<label for="remember" class="">Remember me</label>
					</div>

					<p>
						<strong v-if="status" class="text-xs italic text-green-500">{{
							status
						}}</strong>
					</p>

					<Link
						href="/forgot-password"
						class="text-sm text-gray-600 hover:text-blue-600 hover:underline"
						>Forgot Password?</Link
					>

					<button
						class="rounded-md bg-orange-400 py-2 text-white transition-colors hover:bg-orange-500"
					>
						Login
					</button>
				</form>
			</div>
		</section>
	</Layout>
</template>
